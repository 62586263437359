import axios from 'axios';

const database = "portfolio";
const dataSource = "kowsyap";
const dbPassKey = "Qwerty@123";
const dbUser = "pranaykowsyap";
const apiKey = "785rZk6cESFRAcMUGHwumv7Q9XQpFJQi8hwY2VFooQX9MZ7O0ozdh0FTXOdHucFx";

const apiService = async (collection, endpoint, extra={}) => {
  try {
    var data = JSON.stringify({
        "collection": collection,
        "database": database,
        "dataSource": dataSource,
        ...extra
    });
    const config = {
        method: 'post',
        url: `/api/app/data-qdfilvp/endpoint/data/v1/action/${endpoint}`,
        headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
        'api-key': apiKey,
        },
        data: data
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error in API call:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default apiService;
