import React, { Component } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Splash from "../pages/splash/Splash";
import Education from "../pages/education/EducationComponent";
import Experience from "../pages/experience/Experience";
import Contact from "../pages/contact/ContactComponent";
import Projects from "../pages/projects/Projects";
import { settings } from "../portfolio.js";
import Error404 from "../pages/errors/error404/Error";
import Tray from "../components/tray/tray.js";

export default class Main extends Component {
  render() {
    if (settings.isSplash) {
      return (
        <>
          <div>
            <HashRouter basename="/">
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <Splash {...props} theme={this.props.theme} />
                  )}
                />
                <Route
                  path="/home"
                  render={(props) => (
                    <Home
                      {...props}
                      theme={this.props.theme}
                      callBack={this.props.callBack}
                    />
                  )}
                />
                <Route
                  path="/career"
                  exact
                  render={(props) => (
                    <Experience
                      {...props}
                      theme={this.props.theme}
                      callBack={this.props.callBack}
                    />
                  )}
                />
                <Route
                  path="/academics"
                  render={(props) => (
                    <Education
                      {...props}
                      theme={this.props.theme}
                      callBack={this.props.callBack}
                    />
                  )}
                />
                <Route
                  path="/connect"
                  render={(props) => (
                    <Contact
                      {...props}
                      theme={this.props.theme}
                      callBack={this.props.callBack}
                    />
                  )}
                />
                <Route
                  path="/pk"
                  render={(props) => (
                    <Splash {...props} theme={this.props.theme} />
                  )}
                />
                <Route
                  path="/projects"
                  render={(props) => (
                    <Projects
                      {...props}
                      theme={this.props.theme}
                      callBack={this.props.callBack}
                    />
                  )}
                />
                <Route
                  path="*"
                  render={(props) => (
                    <Error404
                      {...props}
                      theme={this.props.theme}
                      callBack={this.props.callBack}
                    />
                  )}
                />
              </Switch>
            </HashRouter>
          </div>
          <Tray theme={this.props.theme}></Tray>
        </>
      );
    } else {
      return (
        <>
          <div>
            <HashRouter basename="/">
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <Home
                      {...props}
                      theme={this.props.theme}
                      callBack={this.props.callBack}
                    />
                  )}
                />
                <Route
                  path="/home"
                  render={(props) => (
                    <Home
                      {...props}
                      theme={this.props.theme}
                      callBack={this.props.callBack}
                    />
                  )}
                />
                <Route
                  path="/career"
                  exact
                  render={(props) => (
                    <Experience
                      {...props}
                      theme={this.props.theme}
                      callBack={this.props.callBack}
                    />
                  )}
                />
                <Route
                  path="/academics"
                  render={(props) => (
                    <Education
                      {...props}
                      theme={this.props.theme}
                      callBack={this.props.callBack}
                    />
                  )}
                />
                <Route
                  path="/connect"
                  render={(props) => (
                    <Contact
                      {...props}
                      theme={this.props.theme}
                      callBack={this.props.callBack}
                    />
                  )}
                />
                <Route
                  path="/projects"
                  render={(props) => (
                    <Projects
                      {...props}
                      theme={this.props.theme}
                      callBack={this.props.callBack}
                    />
                  )}
                />
              </Switch>
            </HashRouter>
          </div>
          <Tray theme={this.props.theme}></Tray>
        </>
      );
    }
  }
}
