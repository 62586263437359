import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import FeelingProud from "./FeelingProud";
import RubberBand from "react-reveal/RubberBand";
import Watch from "../../components/watch/watch";
import Tray from "../../components/tray/tray";

export default function Greeting(props) {
  const theme = props.theme;
  const greetingData = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')):greeting;
  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <RubberBand>
                <h1 className="greeting-text" style={{ color: theme.text }}>
                  {greetingData.title}
                </h1>
              </RubberBand>
              {greetingData.nickname && (
                <h2 className="greeting-nickname" style={{ color: theme.text }}>
                  ( {greetingData.nickname} )
                </h2>
              )}
              <p
                className="greeting-text-p subTitle"
                style={{ color: theme.secondaryText }}
              >
                {greetingData.summary}
              </p>
              <SocialMedia theme={theme} />
            </div>
          </div>
          <div className="greeting-image-div">
            <FeelingProud theme={theme} />
          </div>
        </div>
        <div className="greeting-foot"></div>
      </div>
    </Fade>
  );
}
