import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import GithubRepoCard from "../../components/githubRepoCard/GithubRepoCard";
import PublicationCard from "../../components/publicationsCard/PublicationCard";
import Button from "../../components/button/Button";
import TopButton from "../../components/topButton/TopButton";
import { Fade } from "react-reveal";
import {
  greeting,
  projectsHeader,
  publicationsHeader,
  publications,
} from "../../portfolio.js";
import ProjectsData from "../../shared/opensource/projects.json";
import "./Projects.css";
import ProjectsImg from "./ProjectsImg";
import apiService from "../../shared/services/api-services.js";
import { RingLoader } from "react-spinners";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data1: [],
      data2: [],
      loading: true,
      error: null,
    };
  }
  async componentDidMount() {
    const collection1 = "projects";
    const collection2 = "publications";
    const endpoint = "find";
    try {
      const response1 = await apiService(collection1, endpoint);
      const response2 = await apiService(collection2, endpoint);
      this.setState({ data1: response1.documents, data2: response2.documents, loading: false });
    } catch (error) {
      this.setState({ data1: ProjectsData.data, data2: publications.data, error: error.message, loading: false });
    }
  }

  render() {
    const theme = this.props.theme;
    return (
      <div className="projects-main">
        <Header theme={theme} callBack={this.props.callBack}/>
        <div className="basic-projects">
          <Fade bottom duration={2000} distance="40px">
            <div className="projects-heading-div">
              <div className="projects-heading-img-div">
                <ProjectsImg theme={theme} />
              </div>
              <div className="projects-heading-text-div">
                <h1
                  className="projects-heading-text"
                  style={{ color: theme.text }}
                >
                  {projectsHeader.title}
                </h1>
                <p
                  className="projects-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {projectsHeader["description"]}
                </p>
              </div>
            </div>
          </Fade>
        </div>
        <div className="repo-cards-div-main">
        {this.state.loading ? <RingLoader size={60} color="#3498db" /> :
          (this.state.data1.map((repo) => {
            return <GithubRepoCard repo={repo} theme={theme} />
          }))}
        </div>
        <Button
          text={"More Projects"}
          className="project-button"
          href={greeting.githubProfile}
          newTab={true}
          theme={theme}
        />
        {this.state.data2.length > 0 ? (
          <div className="basic-projects">
            <Fade bottom duration={2000} distance="40px">
              <div className="publications-heading-div">
                <div className="publications-heading-text-div">
                  <h1
                    className="publications-heading-text"
                    style={{ color: theme.text }}
                  >
                    {publicationsHeader.title}
                  </h1>
                  <p
                    className="projects-header-detail-text subTitle"
                    style={{ color: theme.secondaryText }}
                  >
                    {publicationsHeader["description"]}
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        ) : null}

        <div className="repo-cards-div-main">
          {this.state.data2.map((pub) => {
            return <PublicationCard pub={pub} theme={theme} />;
          })}
        </div>

        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Projects;
