import React, { Component } from "react";
import "./Educations.css";
import DegreeCard from "../../components/degreeCard/DegreeCard.js";
import { degrees } from "../../portfolio";
import { Fade } from "react-reveal";
import apiService from "../../shared/services/api-services";
import { RingLoader } from "react-spinners";

class Educations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      error: null,
    };
  }
  async componentDidMount() {
    const collection = "education";
    const endpoint = "find";
    try {
      const response = await apiService(collection, endpoint);
      this.setState({ data: response.documents, loading: false });
    } catch (error) {
      this.setState({ data: degrees.degrees, error: error.message, loading: false });
    }
  }
  render() {
    const theme = this.props.theme;
    return (
      <div className="main" id="educations">
        <div className="educations-header-div">
          <Fade bottom duration={2000} distance="20px">
            <h1 className="educations-header" style={{ color: theme.text }}>
              Degrees Received
            </h1>
          </Fade>
        </div>
        <div className="educations-body-div">
        {this.state.loading ? <RingLoader size={60} color="#3498db" /> :
          (this.state.data.map((degree) => {
            return <DegreeCard degree={degree} theme={theme} />;
          }))}
        </div>
      </div>
    );
  }
}

export default Educations;
