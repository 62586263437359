import React, { Component } from "react";
import "./Header.css";
import { Fade } from "react-reveal";
import { NavLink, Link } from "react-router-dom";
import { contactPageData, greeting, settings } from "../../portfolio.js";
import SeoHeader from "../seoHeader/SeoHeader";
import Dropdown from "react-bootstrap/Dropdown";
import { allThemes } from "../../theme";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import apiService from "../../shared/services/api-services";

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.backgroundColor = "transparent";
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    style={{ padding: "12px 20px" }}
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      error: null,
    };
  }
  async componentDidMount() {
    const collection = "profile";
    const endpoint = "find";
    try {
      if(!localStorage.getItem('profile')){
        const response = await apiService(collection, endpoint);
        localStorage.setItem('profile',JSON.stringify(response.documents[0]))
        this.setState({ data: response.documents[0], loading: false });  
      } else{
        this.setItem({data: JSON.parse(localStorage.getItem('profile')),loading: false})
      }
    } catch (error) {
      this.setState({ data: {...settings}, error: error.message, loading: false });
    }
  }

  setTheme = (value) => {
    localStorage.setItem("theme", value.name);
    this.props.callBack();
  };

  render() {
    const theme = this.props.theme;
    const link = this.state.data.isSplash ? "/pk" : "home";
    return (
      <Fade top duration={1000} distance="20px">
        {/* <SeoHeader /> */}
        <div>
          <header className="header">
            <NavLink to={link} tag={Link} className="logo">
              <span className="logo-name" style={{ color: theme.text }}>
                KOWSYAP{" "}
                <span style={{ color: theme.body, background: theme.text }}>
                  PRANAY
                </span>
              </span>
            </NavLink>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn">
              <span className="navicon"></span>
            </label>
            <ul className="menu" style={{ backgroundColor: theme.body }}>
              <li>
                <NavLink
                  to="/home"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/academics"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Learning Path
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/career"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Career Odyssey
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/projects"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Endeavors
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/connect"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Connect
                </NavLink>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    as={CustomToggle}
                  >
                    {/* <div className="loader">
                    <span></span>
                  </div> */}
                    <div
                      className="pyramid-loader"
                      style={{
                        "--secTxt": theme.imageHighlight,
                        "--body": theme.body,
                        "--txt": theme.text,
                      }}
                    >
                      <div className="wrapper">
                        <span className="side side1"></span>
                        <span className="side side2"></span>
                        <span className="side side3"></span>
                        <span className="side side4"></span>
                        {/* <span className="shadow"></span> */}
                      </div>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="themeMenu">
                    <Dropdown.Header
                      style={{ margin: "10px", textAlign: "center" }}
                    >
                      Pick Theme
                    </Dropdown.Header>
                    <div className="themeMenuInner">
                      {allThemes.map((e, i) => {
                        return (
                          <div
                            className={`th-item ${
                              e.name === this.props.theme.name ? "active" : ""
                            }`}
                            style={{ backgroundColor: e.body }}
                            key={i}
                            onClick={() => this.setTheme(e)}
                          >
                            <span
                              className="color"
                              style={{ backgroundColor: e.text }}
                            ></span>
                          </div>
                        );
                      })}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </header>
        </div>
      </Fade>
    );
  }
}

export default Header;
