import React, { useState } from "react";
import "./tray.css"; // Import CSS for styling
import Watch from "../watch/watch";

const Tray = (props) => {
  const theme = props.theme;
  const [isOpen, setIsOpen] = useState(false);

  const toggleTray = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`tray ${isOpen ? "open" : ""}`}>
      <div
        className="tray-icon"
        onClick={toggleTray}
        style={{ color: theme.text }}
      >
        {!isOpen && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="2em"
            viewBox="0 0 12 24"
          >
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M10.157 12.711L4.5 18.368l-1.414-1.414l4.95-4.95l-4.95-4.95L4.5 5.64l5.657 5.657a1 1 0 0 1 0 1.414"
            />
          </svg>
        )}
      </div>
      <div className="tray-content">
        <div
          className="tray-icon-x"
          onClick={toggleTray}
          style={{ color: "black" }}
        >
          {isOpen && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M3 16.74L7.76 12L3 7.26L7.26 3L12 7.76L16.74 3L21 7.26L16.24 12L21 16.74L16.74 21L12 16.24L7.26 21zm9-3.33l4.74 4.75l1.42-1.42L13.41 12l4.75-4.74l-1.42-1.42L12 10.59L7.26 5.84L5.84 7.26L10.59 12l-4.75 4.74l1.42 1.42z"
              />
            </svg>
          )}
        </div>
        <Watch theme={theme}></Watch>
      </div>
    </div>
  );
};

export default Tray;
